import React, {
} from 'react';
import algoliasearch from 'algoliasearch';
import { AutocompleteComponents, getAlgoliaResults } from '@algolia/autocomplete-js';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Autocomplete from '../search/autocomplete/autocomplete';
import { AlgoliaShop } from '../../../types/algolia.model';
import SearchAutocompleteHit from '../search/search-autocomplete-hit';

interface ShopAutocompleteProps {
  containerName: string;
  queryValue: string | undefined;
  onHitClick: (hit: AlgoliaShop) => void;
  clear: () => void;
}

function ShopAutocomplete({
  containerName, queryValue, onHitClick, clear,
}: ShopAutocompleteProps) {
  const { t, language } = useI18next();

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APPLICATION_ID!,
    process.env.GATSBY_ALGOLIA_API_KEY!,
  );
  return (
    <Autocomplete
      placeholder={t('Search_ShopPlaceholder')}
      panelContainer={containerName}
      query={queryValue}
      openOnFocus
      onReset={() => {
        clear();
      }}
      getSources={({ query }: { query: any }) => [
        {
          sourceId: 'shops',
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: process.env.GATSBY_ALGOLIA_SHOPS_INDEX!,
                  query,
                  params: {
                    filters: `language:${language}`,
                  },
                },
              ],
            });
          },
          templates: {
            // eslint-disable-next-line react/no-unstable-nested-components, react/no-unused-prop-types
            item({
              item,
              components,
            }: {
              item: AlgoliaShop;
              components: AutocompleteComponents;
            }) {
              return (
                <SearchAutocompleteHit<AlgoliaShop>
                  onHitClick={onHitClick}
                  hit={item}
                  components={components}
                />
              );
            },
            noResults() {
              return t('Search_NotFound');
            },
          },
        },
      ]}
    />
  );
}

export default ShopAutocomplete;
