// extracted by mini-css-extract-plugin
export var btn = "missed-sale-module--btn--fe8b4";
export var container = "missed-sale-module--container--0c350";
export var desc = "missed-sale-module--desc--bc0a7";
export var error = "missed-sale-module--error--b769b";
export var formRow = "missed-sale-module--form-row--ec17e";
export var formRowInput = "missed-sale-module--form-row-input--288d7";
export var formSection = "missed-sale-module--form-section--45ed7";
export var formTitle = "missed-sale-module--form-title--bc8f9";
export var inputError = "missed-sale-module--input-error--ea523";
export var missedSaleForm = "missed-sale-module--missed-sale-form--56e7d";
export var orderImage = "missed-sale-module--order-image--22126";
export var successDesc = "missed-sale-module--success-desc--532de";
export var successTitle = "missed-sale-module--success-title--bca83";
export var title = "missed-sale-module--title--f09ce";