import { type RedirectOut } from '../types/redirect-out.model';
import { type Language } from '../types/enums/language.enum';
import { MissedSale } from '../types/missed-sale.model';

export async function getRedirectLink(
  redirectOut: RedirectOut,
  language: Language,
): Promise<string> {
  return fetch(`${process.env.GATSBY_API_URL}/public/campaigns/redirect`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data: {
        addToTrooperNewsletter: redirectOut.data.addToTrooperNewsletter,
        associationId: redirectOut.data.associationId,
        emailAddress: redirectOut.data.emailAddress,
        firstName: redirectOut.data.firstName,
        lastName: redirectOut.data.lastName,
        language,
        shopId: redirectOut.data.shopId,
      },
    }),
  }).then(async (r) => r.json());
}

export async function addMissedSale(missedSale: MissedSale) {
  const formData = new FormData();
  formData.append('shopId', missedSale.shopId.toString());
  formData.append('orderAmount', missedSale.orderAmount.toString());
  formData.append('orderNumber', missedSale.orderNumber);
  formData.append('orderDate', missedSale.orderDate);
  formData.append('orderImage', missedSale.orderImage);
  formData.append('associationId', missedSale.associationId.toString());
  formData.append('firstName', missedSale.firstName);
  formData.append('lastName', missedSale.lastName);
  formData.append('email', missedSale.email);
  formData.append('language', missedSale.language);

  return fetch(`${process.env.GATSBY_API_URL}/public/missed-sale`, {
    method: 'POST',
    credentials: 'include',
    body: formData,
  }).then(async (response) => {
    if (!response.ok) {
      const data = await response.json();
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
  });
}
