import * as React from 'react';

import { useI18next } from 'gatsby-plugin-react-i18next';
import { Link, graphql } from 'gatsby';
import Layout from '../../components/layout';
import * as styles from './missed-sale.module.scss';
import { getOppositeLanguage } from '../../utils/i18n-utils';
import { AlgoliaAssociation, AlgoliaShop } from '../../types/algolia.model';
import { addMissedSale } from '../../services/campaign.service';
import { MissedSale } from '../../types/missed-sale.model';
import { Language } from '../../types/enums/language.enum';
import { TROOPER_PATHS } from '../../../constants';
import ShopAutocomplete from '../../components/shared/shop-autocomplete/shop-autocomplete';
import AssociationAutocomplete from '../../components/shared/association-autocomplete/association-autocomplete';
import SpinnerDots from '../../components/spinner-dots/spinner-dots';

export interface MissedSaleForm {
  shop: {
    value: {
      id: number;
      name: string;
    } | undefined,
    isValid: boolean;
  };
  orderAmount: {
    value: string;
    isValid: boolean
  },
  orderDate: {
    value: string;
    isValid: boolean;
  },
  orderNumber: {
    value: string;
    isValid: boolean;
  },
  association: {
    value: {
      id: number;
      name: string;
    } | undefined;
    isValid: boolean;
  },
  firstName: {
    value: string;
    isValid: boolean;
  },
  lastName: {
    value:string;
    isValid: boolean;
  },
  email: {
    value: string;
    isValid: boolean;
  },
  orderImage: {
    value:File;
    isValid: boolean
  }
}

function MissedSalePage() {
  const { t, language } = useI18next();
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const initialMissedSaleData = {
    orderAmount: { value: '', isValid: false },
    orderNumber: { value: '', isValid: false },
    orderDate: { value: '', isValid: false },
    email: { value: '', isValid: false },
    firstName: { value: '', isValid: false },
    lastName: { value: '', isValid: false },
    shop: { isValid: false },
    association: { isValid: false },
    orderImage: { isValid: false },
  } as MissedSaleForm;

  const [formState, setFormState] = React.useState<MissedSaleForm>(
    initialMissedSaleData,
  );
  const [showErrors, setShowErrors] = React.useState(false);
  const [isSuccessfullyAdded, setiIsSuccessfullyAdded] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const oppositeLanguage = getOppositeLanguage(language);
  const otherLanguagePath = `/${oppositeLanguage}/${TROOPER_PATHS[oppositeLanguage].missedSale}`;

  const currentDate = new Date();
  const maxDate = new Date(new Date().setDate(currentDate.getDate() - 5)).toISOString().split('T')[0];
  const minDate = new Date(2016, 0, 1).toISOString().split('T')[0];

  const shopsPageUrl = `/${language}/${
    language === 'nl'
      ? TROOPER_PATHS.nl.shops
      : TROOPER_PATHS.fr.shops}`;

  const handleOrderImageButtonClick = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleCurrencyInputChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
    const { name, value } = event.target;
    let formattedValue = value.replace(/€ /g, '');
    if (/^\d*\.?\d{0,2}$/.test(formattedValue) || formattedValue === '') {
      formattedValue = formattedValue.replace(/^0+/, '');

      setFormState((prevFormData) => ({
        ...prevFormData,
        [name]: {
          value: `€ ${formattedValue}`,
          isValid: !!formattedValue,
        },
      }));
    }
  };

  const handleEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
    const { name, value } = event.target;

    setFormState((prevFormData) => ({
      ...prevFormData,
      [name]: {
        value,
        isValid: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value),
      },
    }));
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ): void => {
    const { name, value } = event.target;
    setFormState((prevFormData) => ({
      ...prevFormData,
      [name]: {
        value,
        isValid: !!value,
      },
    }));
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file.size < 1048576) { // 1MB
      setFormState((prevFormData) => ({
        ...prevFormData,
        orderImage: {
          value: file,
          isValid: true,
        },
      }));
    }
  };

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();

    const isValid = Object.values(formState).every((property) => !!property.value && property.isValid);

    setShowErrors(!isValid);
    if (!isValid) {
      return;
    }

    const missedSale: MissedSale = {
      shopId: formState.shop!.value!.id,
      orderAmount: Number(formState.orderAmount.value.replace(/€ /g, '')),
      orderNumber: formState.orderNumber.value,
      orderDate: formState.orderDate.value,
      orderImage: formState.orderImage.value,
      associationId: formState.association!.value!.id,
      firstName: formState.firstName.value,
      lastName: formState.lastName.value,
      email: formState.email.value,
      language: Language[language.toUpperCase() as keyof typeof Language],
    };

    setIsSubmitting(true);
    addMissedSale(missedSale).then(() => {
      setIsSubmitting(false);
      setShowErrors(false);
      setiIsSuccessfullyAdded(true);
    }).catch(() => {
      setIsSubmitting(false);
      setShowErrors(true);
    });
  };

  const onAssociationHitClick = (hit: AlgoliaAssociation) => {
    setFormState((prevFormData) => ({
      ...prevFormData,
      association: {
        value: {
          name: hit.name,
          id: hit.associationId,
        },
        isValid: true,
      },
    }));
  };

  const onShopHitClick = (hit: AlgoliaShop) => {
    setFormState((prevFormData) => ({
      ...prevFormData,
      shop: {
        value: {
          name: hit.name,
          id: hit.shopId,
        },
        isValid: true,
      },
    }));
  };

  const clearAssociationFormState = () => {
    setFormState((prevFormData) => ({
      ...prevFormData,
      association: {
        value: undefined,
        isValid: false,
      },
    }));
  };

  const clearShopFormState = () => {
    setFormState((prevFormData) => ({
      ...prevFormData,
      shop: {
        value: undefined,
        isValid: false,
      },
    }));
  };

  const showDatePicker = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    try {
      if ('showPicker' in event.target) {
        // @ts-ignore: 'event.target.showPicker' is of type 'unknown'
        event.target.showPicker();
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <Layout otherLanguagePath={otherLanguagePath}>
      <div className={styles.container}>
        <h1 className={styles.title}>{t('MissedSale_Title')}</h1>
        <div className={styles.desc}>{t('MissedSale_Desc')}</div>

        <div className={styles.missedSaleForm}>
          { isSuccessfullyAdded
            ? (
              <div>
                <div className={styles.successTitle}>{t('MissedSale_AddedSuccessfullyTitle')}</div>
                <div className={styles.successDesc}>{t('MissedSale_AddedSuccessfullyDesc')}</div>
                <button type="button" className={styles.btn}>
                  <Link to={shopsPageUrl}>{t('MissedSale_AddedSuccessfullyButton')}</Link>
                </button>
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className={styles.formSection}>
                  <div className={styles.formTitle}>{t('MissedSale_YourPurchase')}</div>
                  <div className={`${styles.formRow}
                      ${!formState.shop.isValid && showErrors ? styles.inputError : ''}`}
                  >
                    <label htmlFor="shop">{t('MissedSale_Shop')}</label>
                    <div id="shop-autocomplete-container" />
                    <ShopAutocomplete
                      containerName="#shop-autocomplete-container"
                      queryValue={formState.shop?.value?.name}
                      onHitClick={onShopHitClick}
                      clear={clearShopFormState}
                    />
                  </div>
                  <div className={styles.formRow}>
                    <label htmlFor="order-amount">{t('MissedSale_OrderAmount')}</label>
                    <input
                      className={`${styles.formRowInput} 
                      ${!formState.orderAmount.isValid && showErrors ? styles.inputError : ''}`}
                      type="text"
                      id="order-amount"
                      name="orderAmount"
                      inputMode="decimal"
                      placeholder={t('MissedSale_OrderAmountPlaceholder')}
                      value={formState.orderAmount.value}
                      onChange={handleCurrencyInputChange}
                    />
                  </div>
                  <div className={styles.formRow}>
                    <label htmlFor="order-number">{t('MissedSale_OrderNumber')}</label>
                    <input
                      className={`${styles.formRowInput} 
                      ${!formState.orderNumber.isValid && showErrors ? styles.inputError : ''}`}
                      type="text"
                      id="order-number"
                      name="orderNumber"
                      placeholder={t('MissedSale_OrderNumberPlaceholder')}
                      value={formState.orderNumber.value}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={styles.formRow}>
                    <label htmlFor="order-date">{t('MissedSale_OrderDate')}</label>
                    <input
                      className={`${styles.formRowInput} 
                      ${!formState.orderDate.isValid && showErrors ? styles.inputError : ''}`}
                      type="date"
                      id="order-date"
                      name="orderDate"
                      placeholder={t('MissedSale_OrderDate')}
                      value={formState.orderDate.value}
                      onChange={handleInputChange}
                      onClick={showDatePicker}
                      max={maxDate}
                      min={minDate}
                    />
                  </div>
                  <div className={styles.formRow}>
                    <label htmlFor="order-image">{t('MissedSale_OrderImage')}</label>
                    <div className={`${styles.orderImage} 
                      ${!formState.orderImage.isValid && showErrors ? styles.inputError : ''}`}
                    >
                      <button type="button" onClick={handleOrderImageButtonClick}>{t('MissedSale_OrderImageButton')}</button>
                      {formState.orderImage?.value
                        ? (<div>{formState.orderImage.value?.name}</div>
                        ) : (
                          <div>{t('MissedSale_OrderImagePlaceholder')}</div>)}
                      <input
                        className={styles.formRowInput}
                        ref={fileInputRef}
                        type="file"
                        id="order-image"
                        name="orderImage"
                        accept="image/jpg, image/png, image/gif, .pdf"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.formSection}>
                  <div className={styles.formTitle}>{t('MissedSale_YourData')}</div>
                  <div className={`${styles.formRow} 
                      ${!formState.association.isValid && showErrors ? styles.inputError : ''}`}
                  >
                    <label htmlFor="association">{t('MissedSale_Association')}</label>
                    <div id="association-autocomplete-container" />
                    <AssociationAutocomplete
                      containerName="#association-autocomplete-container"
                      queryValue={formState.association?.value?.name}
                      onHitClick={onAssociationHitClick}
                      clear={clearAssociationFormState}
                    />
                  </div>
                  <div className={styles.formRow}>
                    <label htmlFor="first-name">{t('MissedSale_FirstName')}</label>
                    <input
                      className={`${styles.formRowInput} 
                      ${!formState.firstName.isValid && showErrors ? styles.inputError : ''}`}
                      type="text"
                      id="first-name"
                      name="firstName"
                      value={formState.firstName.value}
                      placeholder={t('MissedSale_FirstNamePlaceholder')}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={styles.formRow}>
                    <label htmlFor="last-name">{t('MissedSale_LastName')}</label>
                    <input
                      className={`${styles.formRowInput} 
                      ${!formState.lastName.isValid && showErrors ? styles.inputError : ''}`}
                      type="text"
                      id="last-name"
                      name="lastName"
                      value={formState.lastName.value}
                      placeholder={t('MissedSale_LastNamePlaceholder')}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={styles.formRow}>
                    <label htmlFor="email">{t('MissedSale_Email')}</label>

                    <input
                      className={`${styles.formRowInput} 
                      ${!formState.email.isValid && showErrors ? styles.inputError : ''}`}
                      type="text"
                      id="email"
                      name="email"
                      value={formState.email.value}
                      placeholder={t('MissedSale_EmailPlaceholder')}
                      onChange={handleEmailInputChange}
                    />
                  </div>
                  <div>
                    {!isSubmitting ? (
                      <button
                        className={styles.btn}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {t('MissedSale_Save')}
                      </button>
                    ) : null}

                    {showErrors && (<div className={styles.error}>{t('MissedSale_InvalidForm')}</div>)}
                    <SpinnerDots isLoading={isSubmitting} showBackdrop={false} />
                  </div>
                </div>
              </form>
            )}
        </div>
      </div>

    </Layout>
  );
}

export const query = graphql`
  query MissedSalePage($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["MissedSale", "Search"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default MissedSalePage;
